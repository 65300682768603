import myVideo from "../assets/bgvid.mp4";
import SEO from "../components/helpers/SEO";
import PeptidesInfo from "../components/peptides/peptidesInfo";
import PeptidesTitle from "../components/peptides/peptidesTitle";

export default function Peptides() {
  return (
    <>
    <SEO title="Valiant Health | Peptides" description="Valiant Health offers a variety of Peptide treatments to help you look and feel your best. Using cutting edge treatments, we have peptide treatments for a variety of problematic symptoms of aging" />
      <div className="relative w-full bg-theme-dark h-auto sm:h-screen overflow-hidden">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block drop-shadow-xl sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative h-full w-full flex flex-col sm:flex-row">
          <div className="h-full w-full sm:w-1/2">
            <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
              <PeptidesTitle />
            </div>
          </div>
        </div>
      </div>
      <PeptidesInfo />
    </>
  );
}
