import SameDayAppointment from "../home/appointments";

export default function PeptidesInfo() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col flex justify-center pt-20 text-theme-white bg-theme-dark items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                Introducing{" "}
                <span className="text-theme-light font-medium">
                  {" "}
                  Peptide Therapy{" "}
                </span>{" "}
                at Valiant Health Men's Clinic—The Cutting-Edge Solution for
                Boosting Vitality, Wellness, and Performance!
              </h1>
              <div className="p-4 flex flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">What is Peptide Therapy?</li>
                    <ul className="list-inside list-none ml-4">
                      <li>
                        - Short chains of amino acids that stimulate natural
                        processes
                      </li>
                      <li>
                        - A breakthrough in modern medicine for optimizing
                        health
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Wide Range of Benefits</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Enhanced Muscle Growth</li>
                      <li>- Improved Skin Elasticity</li>
                      <li>- Boosted Immune Function</li>
                      <li>- Better Sleep Quality</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Customized Treatment Plans</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Tailored to individual needs and goals</li>
                      <li>
                        - Designed for both short-term gains and long-term
                        health
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Experienced Medical Professionals
                    </li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Board-certified Nurse Practitioners</li>
                      <li>
                        - Comprehensive consultations for personalized therapy
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Safety and Efficacy</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Rigorous screening and quality control</li>
                      <li>
                        - Medically-supervised treatments for optimal safety
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Flexible Scheduling</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Convenient appointment times</li>
                      <li>- In-office or virtual consultations available</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Confidentiality Assured</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Private treatment rooms</li>
                      <li>- Secure medical records and data storage</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Advanced Monitoring</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Periodic assessments to gauge progress</li>
                      <li>- Adjustments to treatment plans as needed</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Exclusive Access</li>
                    <ul className="list-inside list-none ml-4">
                      <li>
                        - Be among the first to benefit from this innovative
                        therapy
                      </li>
                      <li>- Limited-time offers for our loyal clients</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light p-2  font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-light">
              Stay Younger, Stronger, Longer with Valiant Health
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
