export default function Copyright() {
  return (
    <div className="w-full flex text-theme-white flex-col">
      <div className="w-full flex text-md font-semibold text-center justify-center pt-2">
        <h1>Copyright 2023 &copy; - Valiant Health - All Rights Reserved</h1>
      </div>
      <div className="flex p-4 justify-center text-center">
        <p className="text-xs max-w-6xl">
          All statements and opinions provided on this website are for
          educational and informational purposes only and we do not diagnose or
          give medical advice via this website.
          <br />
            <br />
           Individuals interested in any of
          our therapies are urged to review all pertinent information and do
          their own research before choosing to participate in this therapy.
          Please note, there is no guarantee of specific results with our care
          and results may vary. Please contact us to discuss your specific
          condition. Per FTC GUIDELINES CONCERNING USE OF ENDORSEMENTS AND
          TESTIMONIALS IN ADVERTISING, PLEASE BE AWARE OF THE FOLLOWING: Federal
          regulation requires us to inform our customers that all product
          reviews, testimonials or endorsements of products sold & services
          received through our office reflects the personal experience of those
          individuals. They are expressing their personal opinions on our
          products and services and that those opinions and experiences may not
          be representative of what every consumer of our products may
          personally experience with the endorsed product.
          <br />
            <br />
           All products and
          services reviews and testimonials are the sole opinions, findings or
          experiences of our customers and not those of our company. Our company
          does not compensate in any way for testimonials or reviews. These
          statements have not been evaluated by the US Food and Drug
          Administration (FDA). We are required to inform you that there is no
          intention, implied or otherwise that represents or infers that these
          products or statements be used in the cure, diagnosis, mitigation,
          treatment, or prevention of any disease.
            <br />
            <br />
           These reviews & testimonials
          do not imply that similar results will happen with your use of our
          products. We have no competent or reliable scientific evidence to
          suggest that the testimonial provider’s experience is due to the use
          of our products. These testimonials are not intended to recommend as a
          diagnosis for specific illnesses or conditions, nor as a product to
          eliminate diseases or other medical conditions or complications. We
          make no medical claims as to the benefits of any of our products to
          improve medical conditions.
        </p>
      </div>
    </div>
  );
}
