import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { MoonLoader } from "react-spinners";

export default function ConsultForm() {
  const [state, handleSubmit] = useForm("xknloljg");
  const [inputs, setInputs] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Service: ""
  });

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Show the loader
    setLoading(true);

    try {
      const response = await handleSubmit(e);

      // Check if the submission was successful
      if (response.ok) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      // Reset the form and loading state after a brief delay
      setTimeout(() => {
        setInputs({
          Name: "",
          Phone: "",
          Email: "",
          Service: ""
        });
        setLoading(false);
        setFormSubmitted(false);
      }, 3000); // 3000 milliseconds (3 seconds) delay
    }
  };

  useEffect(() => {
    if (state.succeeded) {
      // Reset the form and loading state after a brief delay
      setTimeout(() => {
        setInputs({
          Name: "",
          Phone: "",
          Email: "",
          Service: ""
        });
        setLoading(false);
        setFormSubmitted(false);
      }, 3000); // 3000 milliseconds (3 seconds) delay
    }
  }, [state.succeeded]);

  return (
    <>
      <div className="sm:absolute w-5/6 -mt-96 mb-10  border-white border-2 bg-theme-black sm:bg-opacity-100 bg-opacity-70 sm:mt-20 self-center sm:w-80  z-40 sm:right-20  border-opacity-40 sm:drop-shadow-glow rounded-sm sm:bg-theme-dark flex flex-col">
        <div className="w-full h-full  border-b-0">
          <div className=" bg-opacity-70 text-center justify-center items-center flex">
            <h1 className="pr-2 pl-2 pt-6 pb-4 text-5xl text-white  font-thin  flex  text-center">
              Request a Consultation
            </h1>
          </div>
        </div>
        <div className="w-full h-full   ">
          {loading ? (
            <div className="flex justify-center items-center h-80">
              <MoonLoader color={"#ffc107"} loading={loading} size={75} />
              <p>Submitting...</p>
            </div>
          ) : formSubmitted ? (
            <p className="text-center text-2xl font-semibold">
              Form Submitted Successfully!
            </p>
          ) : (
            <form
              className="flex flex-col p-2 justify-center items-center"
              onSubmit={handleFormSubmit}
            >
              <input
                id="Name"
                type="text"
                name="Name"
                className="w-11/12 h-10 m-2 rounded-sm font-thin bg-theme-black bg-opacity-30 text-theme-white tracking-tight p-2"
                placeholder="Name"
                value={inputs.Name}
                onChange={handleInputChange}
              />
              <ValidationError prefix="Name" field="name" errors={state.errors} />
              <input
                id="Phone"
                type="tel"
                name="Phone"
                className="w-11/12 h-10 m-2 rounded-sm font-thin bg-theme-black bg-opacity-30 text-theme-white tracking-tight p-2"
                placeholder="Phone Number"
                value={inputs.Phone}
                onChange={handleInputChange}
              />
              <ValidationError prefix="Phone" field="phone" errors={state.errors} />
              <input
                id="Email"
                type="email"
                name="Email"
                className="w-11/12 h-10 m-2 rounded-sm font-thin bg-theme-black bg-opacity-30 text-theme-white tracking-tight p-2"
                placeholder="Email"
                value={inputs.Email}
                onChange={handleInputChange}
              />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
              <input
                id="Service"
                type="text"
                name="Service"
                className="w-11/12 h-10 m-2 rounded-sm font-thin bg-theme-black bg-opacity-30 text-theme-white tracking-tight p-2"
                placeholder="Service Interested In"
                value={inputs.Service}
                onChange={handleInputChange}
              />
              <ValidationError prefix="Service" field="service" errors={state.errors} />
              <button
                type="submit"
                disabled={loading || formSubmitted} // Disable if submitting or form already submitted
                className="w-11/12  text-2xl h-12 m-2 mt-4 mb-4 bg-theme-light text-theme-white bg-opacity-50 hover:bg-opacity-70 rounded font-thin sm:font-normal"
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
