import myVideo from "../assets/bgvid.mp4";
import SEO from "../components/helpers/SEO";
import IVInfo from "../components/iv-therapy/ivInfo";
import IVTitle from "../components/iv-therapy/ivTitle";

export default function IVHydration() {
  return (
    <>
    <SEO title="Valiant Health | IV Hydration" description="IV Hydration, The cure for a hangover. Say goodbye to feeling awful after a night of fun! Valiant health offers a variety of IV-Hydration options for nausa, dehydration, and many other side effects of drinking alcohol" />
      <div className="relative bg-theme-dark w-full h-auto sm:h-screen overflow-hidden">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block drop-shadow-xl sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative sm:h-full w-full flex flex-col sm:flex-row">
          <div className="sm:h-full w-full sm:w-2/3">
            <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
              <IVTitle />
            </div>
          </div>
        </div>
      </div>
      <IVInfo />
    </>
  );
}
