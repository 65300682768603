import SameDayAppointment from "../home/appointments";

export default function LowTInfo() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col flex justify-center bg-theme-dark pt-20  items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full text-theme-white flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                If you are experiencing one or more of the following symptoms,
                you may be suffering from{" "}
                <span className="text-theme-light font-medium">
                  {" "}
                  Low Testosterone{" "}
                </span>
              </h1>
              <div className="p-4 flex flex-col sm:flex-row flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Decreased libido (sex drive) </li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2  mt-4">Lack of energy</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Decreased strength and endurance
                    </li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Loss of height</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Decreased "enjoyment of life"</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Feeling sad or grumpy</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Loss of strength in erections</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Deterioration in ability to play sports
                    </li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Falling asleep after dinner</li>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Decreased work performance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light text-theme-white p-2  font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-white">
              Stay Younger, Stronger, Longer with Valiant Health
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
