import ValiantLogo from "../../assets/ValiantLogo.png";
import { SiTiktok, SiInstagram, SiFacebook, SiTwitter } from "react-icons/si";

export default function Info() {
  return (
    <div className="w-full  mt-4 p-10 flex flex-col">
      <div className="w-full flex flex-col text-theme-white sm:flex-row justify-center">
        <div className="sm:w-1/4  w-full flex sm:items-start items-center text-center sm:text-start flex-col p-4">
          <h1 className="text-3xl font-medium pb-4">Contact Us</h1>
          <div>
            <span className="block">Valiant Health</span>
            <span className="block">3003 Richmond Rd</span>
            <span className="block">Texarkana, Tx 75503</span>
            <br />
            <br />
            <span className=" opacity-70 font-bold">Phone:</span>
            <span className="pl-2">(430) 455-2941</span>
            <br />
            <span className="font-bold opacity-70">Email:</span>
            <span className="pl-2">info@valianthealth.org</span>
          </div>
        </div>
        <div className="sm:w-1/2  flex  justify-center flex-row">
          <div className="flex w-full flex-col justify-start items-center">
            <h1 className="text-3xl font-medium pb-4">Office Hours</h1>
            <div className="flex flex-col max-w-sm sm:w-2/3">
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2  font-bold">
                  <p className="opacity-70">Monday:</p>
                </div>
                <div className="pl-2">
                  <p>9:00am - 6:00pm</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Tuesday:</p>
                </div>
                <div className="pl-2">
                  <p>9:00am - 6:00pm</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Wednesday:</p>
                </div>
                <div className="pl-2">
                  <p>9:00am - 6:00pm</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Thursday:</p>
                </div>
                <div className="pl-2">
                  <p>9:00am - 6:00pm</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Friday:</p>
                </div>
                <div className="pl-2">
                  <p>9:00am - 12:00pm</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Saturday:</p>
                </div>
                <div className="pl-2">
                  <p>By Appointment</p>
                </div>
              </div>
              <div className="flex justify-between pt-2 flex-row">
                <div className="pr-2 font-bold">
                  <p className="opacity-70">Sunday:</p>
                </div>
                <div className="pl-2">
                  <p>Closed</p>
                </div>
              </div>
              <div className="flex justify-center pt-2 flex-row">
                <div className=" flex flex-row pt-2 text-center font-bold">
                  <h1 className="text-xs">Flexible after-hour appointments available upon request and provider approval</h1>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/4 p-4 sm:p-0 flex text-center flex-col">
          <h1 className="text-3xl font-medium">Follow Us</h1>
          <div className="w-full flex flex-row justify-center">
            <img className="h-44 p-2 object-cover" src={ValiantLogo} alt="" />
          </div>
          <div className="flex justify-center flex-row">
            <div>
              <a href="https://www.facebook.com/profile.php?id=61551417001778">
                <SiFacebook className="text-4xl p-1 m-1 rounded"></SiFacebook>
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/valiant_health_tx/">
                <SiInstagram className="text-4xl p-1 m-1 rounded"></SiInstagram>
              </a>
            </div>
            <div>
              <a href="https://www.tiktok.com/@valianthealth">
                <SiTiktok className="text-4xl p-1 m-1  rounded"></SiTiktok>
              </a>
            </div>
            <div>
              <a href="https://twitter.com/ValiantHealthTX">
                <SiTwitter className="text-4xl p-1 m-1 rounded"></SiTwitter>
              </a>
            </div>
          </div>
          <div className="text-xl font-semibold">
            <a href="https://kodacaleb.dev">Koda
            <span className=" text-purple-500 font-normal">Caleb.dev</span></a>
          </div>
        </div>
      </div>
    </div>
  );
}
