import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import MensHealth from "./pages/MensHealth";
import LowTED from "./pages/Low-T-ED";
import MedicalWeightLoss from "./pages/MedicalWeightLoss";
import Peptides from "./pages/Peptides";
import IVHydration from "./pages/IV-Hydration";
import Staff from "./pages/Staff";
import Contact from "./pages/Contact";
import BodyScan from "./pages/BodyScan";
import PRP from "./pages/PRP";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/mens-health" element={<MensHealth />} />
            <Route path="/low-T-ED" element={<LowTED />} />
            <Route
              path="/medical-weight-loss"
              element={<MedicalWeightLoss />}
            />
            <Route path="/peptides" element={<Peptides />} />
            <Route path="/iv-hydration" element={<IVHydration />} />
            <Route path="/3D-Body-Scan" element={<BodyScan />} />
            <Route path="/PRP-Injections" element={<PRP />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
