import SameDayAppointment from "../home/appointments";

export default function PRPInfo() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col flex justify-center text-theme-white bg-theme-dark pt-20 items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                Discover the Healing Power of{" "}
                <span className="text-theme-light font-medium">
                  {" "}
                  PRP Joint Injections{" "}
                </span>
                —A Natural Solution for Joint Pain and Inflammation!
              </h1>
              <div className="p-4 flex flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">What is PRP?</li>
                    <ul className="list-inside list-none ml-4">
                      <li>
                        - Platelet-Rich Plasma (PRP) derived from your own blood
                      </li>
                      <li>
                        - Concentrated source of growth factors to aid healing
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Effective for Multiple Conditions
                    </li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Osteoarthritis</li>
                      <li>- Tendonitis</li>
                      <li>- Sports Injuries</li>
                      <li>- General Joint Pain</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Minimally Invasive Procedure</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Quick in-office treatment</li>
                      <li>- No general anesthesia required</li>
                      <li>- Short recovery time</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Natural Healing</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Uses your body's own platelets</li>
                      <li>- No synthetic substances</li>
                      <li>- Lower risk of allergic reactions</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Safety and Sterility</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Strict adherence to medical protocols</li>
                      <li>- Use of sterile, single-use equipment</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Before-and-After Care</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Comprehensive consultation</li>
                      <li>- Post-treatment follow-ups for maximum efficacy</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Flexible Scheduling</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Convenient appointment times</li>
                      <li>- Online booking available</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Comfortable Experience</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Relax in our private treatment rooms</li>
                      <li>- Complimentary Wi-Fi and entertainment options</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Membership Benefits</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Exclusive discounts on multiple sessions</li>
                      <li>- Priority booking and special promotions</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light p-2 font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-light">
              Experience a new horizon of joint pain relief with PRP Joint
              Injections!
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
