import myVideo from '../assets/bgvid.mp4';
import SEO from '../components/helpers/SEO';
import PRPInfo from '../components/prp/prpInfo';
import PRPTitle from '../components/prp/prpTitle';

export default function PRP() {
  return (
    <>
    <SEO title="Valiant Health | PRP" description="Valiant Health offers PRP services to help relieve symptoms associated with joint pain" />
      <div className="relative bg-theme-dark w-full h-auto sm:h-screen overflow-hidden">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block drop-shadow-xl sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative sm:h-full w-full flex flex-col sm:flex-row">
        <div className="sm:h-full w-full sm:w-2/3">
          <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
            <PRPTitle />
          </div>
        </div>
      </div>
      </div>
      <PRPInfo />
    </>
  );
}
