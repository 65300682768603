import ContactInfo from "../components/contactPage/contactPageInfo";
import ContactUsForm from "../components/contactPage/contactUsForm";
import SEO from "../components/helpers/SEO";
import SameDayAppointment from "../components/home/appointments";

export default function Contact() {
  return (
    <>
    <SEO title="Valiant Health | Contact" description="Valiant Health is open on all weekdays and offers flexible after hours scheduling" />
      <div className=" h-full sm:mt-40 flex flex-col justify-center align-middle text-center">
        <div className=" w-full h-24"></div>
        <div className="flex flex-col bg-theme-dark sm:flex-row justify-center">
          <ContactInfo />
          <ContactUsForm />
        </div>
        <SameDayAppointment />
      </div>
    </>
  );
}
