import React from "react";
import { SiTiktok, SiInstagram, SiFacebook, SiTwitter } from "react-icons/si";
import { AiFillPhone } from "react-icons/ai";
import styled from "styled-components";

const GradientBackground = styled.div`
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

export default function Contact() {
  return (
    <GradientBackground
      id="contact-section"
      className="w-full ml-auto mr-auto flex justify-center bg-theme-black "
    >
      <div className="flex w-full max-w-7xl sm:flex-row flex-col justify-between ">
        <div className="sm:widget-wrap w-full sm:w-4/5 flex self-center sm:flex-row justify-between ">
          <div className="w-full flex sm:flex-row flex-row justify-between items-center  ">
            <div className="flex flex-row ml-8 items-center sm:w-1/3">
              <div className="  h-5 w-5 items-center flex justify-center mr-2 rounded">
                <AiFillPhone className="text-l text-white self-center" />
              </div>
              <p className="sm:text-sm text-xs text-white font-thin  self-center">
                Call us today <a href="tel:4304552941"> (430) 455-2941 </a>
              </p>
            </div>

            <div className="sm:text-4xl text-2xl widget-wrap sm:w-2/3 flex self-center">
              <div className="flex justify-end w-full pr-2 sm:border-r border-black">
                <a href="https://www.facebook.com/profile.php?id=61551417001778" className="m-1 rounded">
                  <SiFacebook className="p-1 text-white  transition-colors  duration-200 ease-in-out hover:text-theme-dark" />
                </a>
                <a href="https://www.instagram.com/valiant_health_tx/" className="m-1 rounded">
                  <SiInstagram className="p-1 transition-colors text-white duration-200 ease-in-out hover:text-theme-dark" />
                </a>
                <a href="https://www.tiktok.com/@valianthealth" className="m-1 rounded">
                  <SiTiktok className="p-1 transition-colors text-white duration-200 ease-in-out hover:text-theme-dark" />
                </a>
                <a href="https://twitter.com/ValiantHealthTX" className="m-1 rounded">
                  <SiTwitter className="p-1 transition-colors text-white duration-200 ease-in-out hover:text-theme-dark" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="widget-wrap w-full sm:w-1/4 flex self-center">
          <div className="flex justify-end w-full">
            <a 
            href="https://www.optimantra.com/optimus/patient/patientaccess/servicesall?pid=ZXJtUExHUlFTN0ZlYWJ5Z09pS2dFZz09&lid=dnRpUlc0Y29HS282alFBZGdpOVNSdz09"
            className="border flex justify-center w-full font-extralight sm:w-auto p-2  border-white text-white rounded transition-transform duration-500 ease-in-out transform hover:scale-95">
              Book Appointment Online
            </a>
          </div>
        </div>
      </div>
    </GradientBackground>
  );
}
