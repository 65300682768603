import SameDayAppointment from "../home/appointments";

export default function MWLInfo() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col flex justify-center bg-theme-dark text-theme-white pt-20 items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                Transform Your Life with Our{" "}
                <span className="text-theme-light font-medium">
                  {" "}
                  Medically Assisted Weight Loss{" "}
                </span>
                Program—The Safe, Effective, and Personalized Approach to
                Achieving Your Goals!
              </h1>
              <div className="p-4 flex flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Science-Backed Methods</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Proven weight loss medications</li>
                      <li>- Evidence-based strategies</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Customized Plans</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Tailored to your individual needs</li>
                      <li>- Adjusted based on your progress</li>
                      <li>- Focus on long-term sustainability</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Nutritional Guidance</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Custom meal planning</li>
                      <li>- Nutritional education for lifelong success</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Personalized Coaching</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- One-on-one support from weight loss experts</li>
                      <li>- Weekly check-ins and accountability</li>
                      <li>- Motivational resources to keep you on track</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Exercise Plans</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Designed by certified trainers</li>
                      <li>- Adapted to your fitness level</li>
                      <li>- Inclusive of home and gym options</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Comprehensive Monitoring</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Regular weigh-ins and measurements</li>
                      <li>- Health markers like cholesterol and blood sugar</li>
                      <li>- Adapt plans based on your evolving needs</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Safety First</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Pre-screening medical evaluation</li>
                      <li>- Ongoing health assessments</li>
                      <li>- Managed by board-certified nurse practitioners</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Flexible Scheduling</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Evening and weekend appointments available</li>
                      <li>- Virtual consultations for your convenience</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light text-theme-white p-2  font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-4 h-full ">
            <h1 className="font-extralight text-4xl text-theme-white">
              Take the first step toward achieving your weight loss goals with
              our{" "}
              <span className="text-theme-light font-medium">
                {" "}
                Medically Assisted Weight Loss{" "}
              </span>{" "}
              Program at Valiant Health—your healthiest life is just a call
              away!
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
