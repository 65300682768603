import myVideo from "../assets/bgvid.mp4";
import LowTInfo from "../components/Low-T/lowT-Info";
import LowTTitle from "../components/Low-T/lowT-Title";
import SEO from "../components/helpers/SEO";

export default function LowTED() {
  return (
    <>
    <SEO title="Valiant Health | Low Testosterone" description="Valiant Health specializes in treating symptoms of Low Testosterone which naturally occurs as we age." />
      <div className="relative w-full bg-theme-dark h-auto sm:h-screen overflow-hidden">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative sm:h-full w-full flex flex-col sm:flex-row">
          <div className="sm:h-full w-full sm:w-2/3">
            <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
              <LowTTitle />
            </div>
          </div>
        </div>
      </div>
      <LowTInfo />
    </>
  );
}
