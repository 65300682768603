import SameDayAppointment from "../home/appointments";

export default function MensHealthInfoSection() {
  return (
    <>
      <div className=" w-full sm:h-full  flex-col flex justify-center bg-theme-dark  pt-20  items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl text-theme-white font-extralight self-center text-center">
                Welcome to{" "}
                <span className=" font-medium text-theme-light">
                  Valiant Health
                </span >
                , Texarkana's Premier Men's Health Clinic!
              </h1>
              <div className="p-4 flex text-theme-white flex-wrap">
                <div className="sm:w-1/2 ">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Comprehensive Primary Care</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Routine Check-ups</li>
                      <li>- Preventive Screenings</li>
                      <li>- Diagnostic Tests</li>
                      <li>
                        - Personalized healthcare plans designed just for men
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Medically Assisted Weight Loss
                    </li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Science-backed Programs</li>
                      <li>- Nutritional Counseling</li>
                      <li>- Exercise Plans</li>
                      <li>
                        - Achieve and maintain your ideal weight the healthy
                        way!
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Hormone Replacement Therapy (HRT)
                    </li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Expert Consultations</li>
                      <li>- Safe and Effective Treatments</li>
                      <li>
                        - Restore hormonal balance and improve your quality of
                        life
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Experienced Medical Team</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Board-Certified Nurse Practitioners</li>
                      <li>- Confidential and Compassionate Care</li>
                      <li>- Comfortable, Private Consultation Rooms</li>
                      <li>- Conveniently Located in Texarkana</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">
                      Flexible Appointment Scheduling
                    </li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Online Booking Available</li>
                      <li>- Evening and Weekend Appointments</li>
                      <li>- Your health on your schedule!</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light text-theme-white p-2 hover:border-black font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-white">
              Stay Younger, Stronger, Longer with Valiant Health
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
