import myVideo from '../assets/bgvid.mp4';
import BodyScanInfoSection from '../components/bodyScan/bodyScanInfoSection';
import BodyScanTitle from '../components/bodyScan/bodyScanTitle';
import SEO from '../components/helpers/SEO';

export default function BodyScan() {
  return (
    <>
    <SEO title="Valiant Health | 3D Body Scan" description="Revolutionize the way you treat your gains. Valiant Health offers a 3D Body Scan that will give you a detailed report of your body composition. This will help you track your progress and make sure you are on the right track to reaching your goals." />
      <div className="relative bg-theme-dark w-full  h-auto sm:h-screen overflow-hidden">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block drop-shadow-xl sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative sm:h-full w-full flex flex-col sm:flex-row">
        <div className="sm:h-full w-full sm:w-2/3">
          <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
            <BodyScanTitle />
          </div>
        </div>
      </div>
      </div>
      <BodyScanInfoSection />
    </>
  );
}
