import myVideo from '../assets/bgvid.mp4';
import SEO from '../components/helpers/SEO';
import MensHealthInfoSection from '../components/mensHealth/mensHealthInfoSection';
import MensHealthTitle from "../components/mensHealth/mensHealthTitle";

export default function MensHealth() {
  return (
    <>
    <SEO title="Valiant Health | Men's Health" description="Valiant Health offers specialized health services dedicated to Men and their health needs." />
      <div className="relative w-full bg-theme-dark h-auto sm:h-screen overflow-hidden ">
        <video
          playsInline
          autoPlay
          loop
          muted
          className=" absolute hidden sm:block sm:scale-110 -top-5 w-full min-w-full z-0 bg-transparent"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative  sm:h-full w-full flex flex-col sm:flex-row">
        <div className="sm:h-full w-full sm:w-2/3">
          <div className="sm:w-full sm:h-full sm:p-2 flex relative justify-center items-center sm:pt-4">
            <MensHealthTitle />
          </div>
        </div>
      </div>
      </div>
      <MensHealthInfoSection />
    </>
  );
}
