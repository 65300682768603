export default function PeptidesTitle() {
  return (
      <div  className=" relative w-full mt-4 sm:w-full items-center z-40 pb-10 sm:mr-12 sm:mb-28 justify-center flex flex-col ">
          <div className="sm:mr-40 pt-10 bg-opacity-60">
              <h1 className=" pr-2 pl-2 w-full pt-4 pb-4 text-5xl sm:text-6xl font-semibold text-white text-center"> Peptides </h1>
          </div>
          {/* <div className="bg-amber-200 rounded-sm mt-1 sm:w-3/4 sm:ml-8 bg-opacity-60">
              <h2 className="p-2 font-semibold text-2xl sm:text-3xl text-amber-900 text-center">Telehealth Available</h2>
          </div> */}
      </div>
  )
}