import SameDayAppointment from "../home/appointments";

export default function IVInfo() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col flex bg-theme-dark justify-center pt-20 text-theme-white items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                Elevate Your Wellness with{" "}
                <span className="text-theme-light font-medium">
                  {" "}
                  IV Infusion Therapy{" "}
                </span>{" "}
                at Valiant Health—Fast, Effective, and Customized to Your Needs!
              </h1>
              <div className="p-4 flex flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">What is IV Infusion Therapy</li>
                    <ul className="list-inside list-none ml-4">
                      <li>
                        - Direct intravenous delivery of vital nutrients,
                        vitamins, and minerals
                      </li>
                      <li>
                        - Bypasses the digestive system for faster absorption
                        and maximum efficacy
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Tailored Therapy Options</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Immune Boosting</li>
                      <li>- Energy Enhancement</li>
                      <li>- Detoxification</li>
                      <li>- Anti-Aging</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Quick and Convenient</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- 30-60 minute treatment sessions</li>
                      <li>- Immediate feel-good effects</li>
                      <li>- Perfect for busy lifestyles</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Expert Medical Team</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Board-Certified Nurse Practitioners</li>
                      <li>
                        - Individualized consultations for targeted treatment
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Cutting-Edge Technology</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Sterile infusion equipment</li>
                      <li>
                        - High-quality, medically-approved,{" "}
                        <span className="font-semibold">
                          {" "}
                          PHARMACEUTICALLY PREPARED{" "}
                        </span>{" "}
                        formulations
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Safe and Secure</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Comprehensive pre-treatment screening</li>
                      <li>
                        - Strict adherence to safety protocols and sterilization
                        standards
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Flexible Scheduling</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Weekday and weekend slots</li>
                      <li>- Easy online booking available</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Comfortable Experience</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Relax in our private treatment rooms</li>
                      <li>- Complimentary Wi-Fi and entertainment options</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Membership Benefits</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Exclusive discounts on multiple sessions</li>
                      <li>- Priority booking and special promotions</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light p-2 font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-light">
              Stay Younger, Stronger, Longer with Valiant Health
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
