import { PiCalendarCheckDuotone } from "react-icons/pi";


export default function SameDayAppointment() {
    return (
        <div className="h-auto  w-full sm:mb-16 pt-20 pb-20 shapedividers_com-8533 bg-theme-black flex flex-col">
            <div className="flex flex-row mt-60 justify-center w-full">
                <PiCalendarCheckDuotone className="text-6xl text-theme-light" />
            </div>
            <div className="flex flex-col items-center justify-center text-theme-white w-full">
                <h1 className="font-semibold text-center text-3xl">Same Day Appointments Available</h1>
                <p className="font-extralight text-xl mt-4">We can see you the same day you call!</p>
            </div>
            <div className="flex flex-row justify-center mt-8">
            <a
            href="/contact"
             className="p-2 border-t font-semibold border-b text-theme-white border-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95">
                CONTACT US
              </a>
            </div>
        </div>
    )
}