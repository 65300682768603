import BodyScanCard from "./bodyScanCard";

export default function ServiceCards() {
  return (
    <div className="w-full h-full -mt-20  pb-20 flex flex-col bg-theme-dark justify-center items-center ">
      <div className="h-32 w-full justify-center flex flex-row">
        <h1 className="self-center text-3xl text-theme-white border-b-2 sm:mt-0 mt-10 font-extralight"> SERVICES </h1>
      </div>
      <div className="flex flex-row flex-wrap justify-evenly items-start h-auto">
        <div className=" h-96 flex flex-col bg-theme-dark sm:w-64 m-4">
          
          <div className=" rounded-t-md bg-theme-white  transition-colors duration-400 ease-in-out flex flex-row justify-center h-40 w-full">
            <h1 className="self-center text-3xl sm:text-2xl text-theme-brown sm:font-semibold ">
              Mens Health
            </h1>
          </div>
          

          <div className="h-full bg-theme-dark m-2">
            <div className="flex flex-col text-center justify-between h-full ">
              <p className="p-2 cursor-default text-theme-white font-extralight">
                Comprehensive Men's Health Services: Prioritize your well-being
                with our tailored men's health solutions.
              </p>
              <a
                href="/mens-health"
                className="p-2 border-t border-b text-theme-light border-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>

        <div className=" h-96 flex flex-col bg-theme-dark sm:w-64 m-4">
          <div className="bg-theme-white rounded-t-md  transition-colors duration-400 ease-in-out flex flex-row justify-center text-center h-40 w-full">
            <h1 className=" self-center text-3xl drop-shadow text-theme-brown sm:text-2xl sm:font-semibold  cursor-default">
              Testosterone Replacement Therapy
            </h1>
          </div>
          <div className="h-full bg-theme-dark m-2">
            <div className="flex flex-col text-center justify-between h-full ">
              <p className="p-2 font-extralight text-theme-white cursor-default">
                Low energy, muscle mass and stamina in the bedroom? No cookie
                cutter, one dose fits all mentality here! We use individual lab
                results and work with you to determine optimum performance at
                any age.
              </p>
              <a
                href="/low-t-ED"
                className="p-2 border-t text-theme-light border-b border-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>

        <div className=" h-96 flex flex-col bg-theme-dark sm:w-64 m-4">
          <div className="bg-theme-white rounded-t-md transition-colors duration-400 ease-in-out   flex flex-row justify-center text-center h-40 w-full">
            <h1 className=" self-center text-3xl sm:text-2xl text-theme-brown drop-shadow sm:font-semibold  cursor-default">
              Medical Weight Loss
            </h1>
          </div>
          <div className="h-full bg-theme-dark m-2">
            <div className="flex flex-col text-center justify-between h-full ">
              <p className="p-2 cursor-default text-theme-white font-extralight">
                Losing weight is hard. Food tastes good and exercise can be a
                difficult commitment. Get a competitive edge with results driven
                motivation.
              </p>
              <a
                href="/medical-weight-loss"
                className="p-2 border-t border-b border-theme-light text-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>

        <div className=" h-96 flex flex-col bg-theme-dark sm:w-64 m-4">
          <div className="bg-theme-white rounded-t-md  transition-colors duration-400 ease-in-out  flex flex-row justify-center h-40 w-full">
            <h1 className="self-center text-3xl sm:text-2xl text-theme-brown sm:font-semibold  cursor-default">
              Peptides
            </h1>
          </div>
          <div className="h-full bg-theme-dark m-2">
            <div className="flex flex-col text-center justify-between h-full ">
              <p className="p-2 cursor-default text-theme-white font-extralight">
                Elevate Your Health with Peptide Therapy: Discover the
                transformative benefits of peptide therapy, a cutting-edge
                approach to optimizing your well-being
              </p>
              <a
                href="/peptides"
                className="p-2 border-t border-b text-theme-light border-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>

        <div className=" h-96 flex flex-col bg-theme-dark sm:w-64 m-4">
          <div className=" bg-theme-white rounded-t-md  transition-colors duration-400 ease-in-out flex flex-row justify-center text-center h-40 w-full">
            <h1 className="self-center text-3xl text-theme-brown sm:text-2xl sm:font-semibold cursor-default">
              IV-Hydration
            </h1>
          </div>
          <div className="h-full bg-theme-dark m-2">
            <div className="flex flex-col text-center justify-between h-full ">
              <p className="p-2 cursor-default text-theme-white font-extralight">
                IV therapy, or vitamin infusion, supplements your body with
                vitamins that may be lacking.
              </p>
              <a
                href="/iv-hydration"
                className="p-2 border-t border-b text-theme-light border-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>
      </div>
      <BodyScanCard />
    </div>
  );
}
