export default function BodyScanCard() {
    return (
        <div className="w-full flex justify-center flex-col h-full ">
            <div className="flex-row sm:mt-20 sm:mb-20 mb-10 mt-10 mr-5 ml-5 flex justify-center text-center">
                <h1 className="font-extralight text-theme-white text-3xl">Revolutionize the way you view fitness with our <span className="font-normal text-3xl text-theme-light">3D Body Scanning</span></h1>
            </div>
            <a
                href="/3D-Body-Scan"
                className="p-2 border-t sm:w-1/6 self-center text-center border-b border-theme-light text-theme-light transition-transform duration-500 ease-in-out transform hover:scale-95"
              >
                LEARN MORE
              </a>
        </div>
    )
}