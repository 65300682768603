import { Outlet } from "react-router-dom"
import React from "react"
import Contact from "./header/contact"
import Nav from "./header/nav"
import Info from "./footer/info"
import Copyright from "./footer/copyright"


export default function Layout() {
    return (
        <div className="w-full flex flex-col bg-theme-black ">
            <div className="w-full">
                <Contact />
                {/* conditional render two dif nav components for mobile/desktop */}
                <Nav />
            </div>
            <Outlet />
            <div className="w-full flex-col flex">
                <Info />
                <Copyright />
            </div>
        </div>
    )
}