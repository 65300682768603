import ConsultForm from "../components/home/consultForm";
import myVideo from "../assets/bgvid.mp4"; // replace with the path to your video
import ServiceCards from "../components/home/serviceCards";
import SameDayAppointment from "../components/home/appointments";
import SEO from "../components/helpers/SEO";

export default function Home() {
  return (
    <>
    <SEO title="Valiant Health | Texarkana, TX | Men's Health Clinic" description="Texarkana's first Men's Health Clinic. We offer a variety of services including Testerone Replacement Therapy, IV Hydration, Medical Weight Loss, and more!" />
      <div className="reletive flex flex-col sm:flex-row-reverse w-full mb-8  shapedividers_com-8792  h-full">
        <video
          playsInline
          autoPlay
          loop
          muted
          className="z-0 bg-transparent vidHeight sm:h-full object-cover w-full"
        >
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <ConsultForm />
      </div>
      <ServiceCards />
      <SameDayAppointment />
    </>
  );
}
