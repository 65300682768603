import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import ValiantLogo from "../../assets/ValiantLogo.png";
import { FiMenu } from "react-icons/fi";
import { motion } from "framer-motion";

export default function Nav() {
  // set up two components, one for mobile and one for desktop
  const [menuOpen, setMenuOpen] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight =
        document.getElementById("contact-section").offsetHeight;
      const scrollPos = window.scrollY;

      if (scrollPos > offsetHeight) {
        setIsNavFixed(true);
      } else {
        setIsNavFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoVariants = {
    small: { height: 110 }, // Adjust the height accordingly
    large: { height: 160 }, // Adjust the height accordingly
  };

  const DesktopNav = () => (
    <div
      className={`w-full flex absolute flex-row justify-center ${
        isNavFixed
          ? "bg-black bg-opacity-90 fixed-nav z-50"
          : "bg-fade-gradient z-20"
      }`}
    >
      <div className="flex mr-6 flex-row text-sm font-semibold max-w-7xl justify-center items-center">
        <div className="pr-4 pl-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/">
            Home
          </Link>
        </div>
        <div className="pr-4 pl-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className=" text-theme-white hover:text-theme-light" to="/mens-health">
            Men's Health
          </Link>
        </div>
        <div className="pr-4 pl-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/low-T-ED">
            Low-T/ED
          </Link>
        </div>
        <div className="pr-4 pl-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/medical-weight-loss">
            Medical Weight Loss
          </Link>
        </div>
        <div className="pr-4 pl-4 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/3D-Body-Scan">
            3D Body Scan
          </Link>
        </div>
        <div className="h-auto">
          <motion.img
            className="object-cover pl-6"
            src={ValiantLogo}
            alt=""
            initial={isNavFixed ? "large" : "small"}
            animate={isNavFixed ? "small" : "large"}
            variants={logoVariants}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
        </div>
        <div className="pl-4 pr-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/peptides">
            Peptides
          </Link>
        </div>
        <div className="pl-4 pr-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:hover:text-theme-light" to="/iv-hydration">
            IV-Hydration
          </Link>
        </div>
        <div className="pl-4 pr-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:hover:text-theme-light" to="/PRP-Injections">
            PRP Injections
          </Link>
        </div>
        <div className="pl-4 pr-2  w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:hover:text-theme-light" to="/staff">
            Meet the Staff
          </Link>
        </div>
        <div className="pl-4 pr-2 w-[150px] md:w-[150px] text-center">
          <Link onClick={goToTop} className="text-theme-white hover:text-theme-light" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );

  const MobileNav = () => (
    <div className="relative w-full flex flex-row z-50  items-center justify-between">
      <img className=" h-24 ml-4 object-cover p-1" src={ValiantLogo} alt="" />
      <div className="mr-16">
        <div
          className="text-2xl bg-gray-300 p-1 bg-opacity-40 rounded cursor-pointer" // Updated classes
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FiMenu />
        </div>
      </div>
      {/* Dropdown Menu */}
      {menuOpen && (
        <div className="absolute top-full right-0 w-full bg-white shadow-md rounded border z-10">
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/">
            Home
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/mens-health">
            Men's Health
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/low-T-ED">
            Low-T/ED
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/medical-weight-loss">
            Medical Weight Loss
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/peptides">
            Peptides
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/iv-hydration">
            IV Hydration
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/3D-Body-Scan">
            3D Body Scan
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/PRP-Injections">
            PRP Injections
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/staff">
            Meet the Staff
          </Link>
          <Link onClick={() => setMenuOpen(!menuOpen)} className="block p-2 text-center" to="/contact">
            Contact Us
          </Link>
        </div>
      )}
    </div>
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  return (
    <div className="w-full">
      {/* conditionally return seperate navbars */}
      {isNavFixed && <div className="h-16"></div>}
      {isDesktopOrLaptop && <DesktopNav />}
      {isMobile && <MobileNav />}
    </div>
  );
}
