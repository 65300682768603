import SameDayAppointment from "../home/appointments";

export default function BodyScanInfoSection() {
  return (
    <>
      <div className=" w-full sm:h-full flex-col text-theme-white bg-theme-dark flex justify-center pt-20 items-center">
        <div className="sm:w-4/5 mb-20 flex-col">
          <div className="w-full flex ">
            <div className="w-full flex flex-col justify-start pl-4 pr-4">
              <h1 className="text-4xl font-extralight self-center text-center">
                Introducing the Revolutionary{" "}
                <span className="font-medium text-theme-light">
                  {" "}
                  Fit 3D Body Scan{" "}
                </span>{" "}
                - The Future of Personal Health and Fitness!
              </h1>
              <div className="p-4 flex flex-wrap">
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Precise Measurements</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Capture every contour and dimension</li>
                      <li>- Precise measurements for unparalleled detail</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Comprehensive Body Analysis</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Body Fat Percentage</li>
                      <li>- Lean Muscle Mass</li>
                      <li>- Detailed and Accurate Measurements</li>
                      <li>- Posture Assessment</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Quick and Easy Scans</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Full-body scan in under 60 seconds</li>
                      <li>
                        - User-friendly interface for effortless operation
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Privacy-Focused</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Secure data storage</li>
                      <li>- Confidential results available to you</li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Cutting-Edge Technology</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Utilizes advanced sensors and optics</li>
                      <li>
                        - High-resolution 3D images for in-depth analysis{" "}
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Personalized Fitness Plans</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Tailored workout routines based on scan data</li>
                      <li>
                        - Optimize your exercise and nutrition for targeted
                        results{" "}
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Health Monitoring</li>
                    <ul className="list-inside list-none ml-4">
                      <li>- Track changes over time</li>
                      <li>
                        - Ideal for weight loss, muscle gain, or medical
                        monitoring{" "}
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="sm:w-1/2">
                  <ul className="list-disc list-inside">
                    <li className="mb-2 mt-4">Multi-Platform Compatability</li>
                    <ul className="list-inside list-none ml-4">
                      <li>
                        - Access your data from smartphones, tablets, or
                        computers
                      </li>
                      <li>
                        - Seamlessly integrates with popular fitness apps{" "}
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 flex justify-center sm:justify-end ">
            <a
              href="/"
              className=" text-2xl opacity-75 border-t border-b border-theme-light p-2  font-semibold transition-transform duration-500 ease-in-out transform hover:scale-95"
            >
              REQUEST APPOINTMENT
            </a>
          </div>
          <div className="flex w-full mt-8 mb-8 justify-center text-center p-2 h-full ">
            <h1 className="font-semibold text-4xl text-theme-light">
              Stay Younger, Stronger, Longer with Valiant Health
            </h1>
          </div>
        </div>
      </div>
      <SameDayAppointment />
    </>
  );
}
