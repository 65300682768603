import SEO from "../components/helpers/SEO";
import SameDayAppointment from "../components/home/appointments";

export default function Contact() {
    
        return (
            <>
            <SEO title="Valiant Health | Staff" description=" We at Valiant Health is excited to introduce our staff" />
            <div className=" h-full sm:mt-40 flex flex-col bg-theme-dark justify-center align-middle text-center">
                <h1 className="text-red-400 text-3xl mt-10 mb-4"> COMING SOON </h1>
                <SameDayAppointment />
            </div>
            </>
        )
    }