export default function ContactInfo() {
    return(
        <div className="w-1/2 mb-10 flex flex-col text-theme-white self-center sm:mt-20 sm:mr-10">
            <h1 className="font-medium text-4xl mt-20 text-theme-light sm:mt-6">
                Sign up and recieve a call from our team to schedule an appointment!
            </h1>
            <div className="self-center italic mt-10">
            <h1>
                you can also reach us at: 
            </h1>
            <h2 className="mt-4">
                Phone: (430) 455-2941
            </h2>
            <h2>
                or
            </h2>
            <h2>
                Email: info@valianthealth.org
            </h2>
            </div>

        </div>
    )
}